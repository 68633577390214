<template>
    <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container-fluid py-8">
            <div class="d-flex flex-row">
                <!--begin::Content-->
                <div class="flex-row-fluid ">
                    <Widget15 corpid codegroupid></Widget15>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Widget15 from "@/view/content/widgets/admin/Widget15.vue";

export default {
    name: 'corporatecodes',
    components: {
        Widget15
    },
    computed: {
        corpid() {
            return this.$route.params.corpId
        },
        codegroupid() {
            return this.$route.params.codegroupId
        }

    },
}
</script>
