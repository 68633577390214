<template>
    <div class="row">
        <div class="col-xl-3">
            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder text-dark fs-15">Information</span>
                    </h3>
                </div>
                <form class="form" novalidate="novalidate" id="kt_save_code_form">
                    <div class="card-body pt-2">
                        <div>
                            <div class="form-group mb-8">
                                <label class="font-weight-bolder">Total</label>
                                <b-form-spinbutton class="form-control form-control-solid form-control-lg"
                                    v-model="total" :min="total" :max="total" readonly></b-form-spinbutton>
                            </div>

                        </div>
                        <div>
                            <div class="form-group mb-8">
                                <label class="font-weight-bolder">Remaining</label>
                                <b-form-spinbutton class="form-control form-control-solid form-control-lg"
                                    v-model="remaining" :min="remaining" :max="remaining" readonly></b-form-spinbutton>
                            </div>

                        </div>
                        <div>
                            <div class="form-group mb-8">
                                <label class="font-weight-bolder">Used</label>
                                <b-form-spinbutton class="form-control form-control-solid form-control-lg"
                                    v-model="used" :min="used" :max="used" readonly></b-form-spinbutton>
                            </div>

                        </div>
                        <div>
                            <div class="form-group mb-8">
                                <label class="font-weight-bolder">Start Date</label>
                                <b-form-datepicker name="startdate" ref="startdate" v-model="form.startdate"
                                    class="form-control form-control-solid form-control-lg">
                                </b-form-datepicker>
                            </div>

                        </div>
                        <div>
                            <div class="form-group mb-8">
                                <label class="font-weight-bolder">End Date</label>
                                <b-form-datepicker name="enddate" ref="enddate" v-model="form.enddate"
                                    class="form-control form-control-solid form-control-lg">
                                </b-form-datepicker>
                            </div>

                        </div>
                        <div>
                            <div class="form-group mb-8">
                                <label class="font-weight-bolder">Status</label>
                                <b-form-select name="online" ref="online" v-model="form.online" :options="options"
                                    class="form-control form-control-solid form-control-lg"></b-form-select>
                            </div>

                        </div>
                        <button ref="kt_save_code_form" class="btn btn-primary font-weight-bolder mr-2 px-8"
                            :disabled="isDisabled">Update</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-xl-9">
            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder text-dark fs-15">Codes</span>
                    </h3>
                    <div class="card-toolbar">
                        <ul class="nav nav-pills nav-pills-sm nav-dark-75">
                            <li class="nav-item">
                                <export-excel class="btn btn-ingomugreen py-2 px-4 active mr-2 btn-sm" :data="codeList"
                                    :fields="excel_fields" worksheet="Codes" name="IngomuCorpCodes.xls">
                                    Excel
                                </export-excel>
                            </li>
                        </ul>
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-2 pb-0" v-if="codeList.length > 0">
                    <div class="mt-5 mb-5">
                        <b-form-group class="mb-0">
                            <b-input-group size="sm">
                                <b-form-input id="filter-input" v-model="filter" type="search"
                                    placeholder="Type to Search">
                                </b-form-input>
                                <b-input-group-append>
                                    <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </div>
                    <!--begin::Table-->
                    <b-table id="latest-sessions-table" :items="codeList" :fields="fields" :per-page="perPage"
                        :current-page="currentPage" show-empty :filter="filter" :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        class="table table-borderless table-vertical-center obcover br-42 align-self-center">
                        <template #cell(image)="data" class="pl-0 py-4">
                            <div class="symbol symbol-50 symbol-light mr-1">
                                <span class="symbol-label">
                                    <a href="javascript:void(0)"
                                        class="symbol-label text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                        <img :src="imageSource(data.item.user.profileimage)"
                                            class="h-100 w-100 obcover br-42 align-self-center" alt=""
                                            v-if="data.item.user !== undefined" />
                                        <img :src="imageSource(null)"
                                            class="h-100 w-100 obcover br-42 align-self-center" alt="" v-else />
                                    </a>
                                </span>
                            </div>
                        </template>
                        <template #cell(name)="data" class="pl-0 py-4">
                            <a href="javascript:void(0)"
                                class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                {{ data.item.code }}
                            </a>
                            <a href="javascript:void(0)" class="text-dark font-weight-bold d-block"
                                v-if="'user' in data.item">{{ data.item.user.email }}</a>
                        </template>
                        <template #cell(expiredate)="data" class="pl-0 py-4">
                            <a href="javascript:void(0)">
                                {{ shortFormatDate(data.item.expiration_date) }}
                            </a>
                        </template>
                        <template #cell(redeemdate)="data" class="pl-0 py-4">
                            <a href="javascript:void(0)">
                                {{ shortFormatDate(data.item.redeem_date) }}
                            </a>
                        </template>
                        <template #cell(isused)="data" class="pl-0 py-4">
                            <button class="label label-lg label-inline label-light-success mr-2 mt-2"
                                v-if="data.item.isused"> Used </button>
                            <button class="label label-lg label-inline label-light-danger mr-2 mt-2" v-else> Not Used
                            </button>
                        </template>
                        <template #cell(status)="data" class="pl-0 py-4">
                            <button @click="changeOnlineStatus(false, data)"
                                class="label label-lg label-inline label-light-success mr-2 mt-2"
                                v-if="data.item.online" :disabled="onlineClick.includes(data.item.id)"> Online </button>
                            <button @click="changeOnlineStatus(true, data)"
                                class="label label-lg label-inline label-light-danger mr-2 mt-2"
                                :disabled="onlineClick.includes(data.item.id)" v-else> Offline
                            </button>
                        </template>
                    </b-table>
                </div>
                <b-card-footer>
                    <div class="row mt-7 mb-7">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="left">
                        </b-pagination>
                    </div>
                </b-card-footer>
            </div>

        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import defaultProfileImageMixin from "@/mixins/defaultProfileImage";
import formateDateMixin from "@/mixins/formatDate";
import Swal from "sweetalert2";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import KTUtil from "@/assets/js/components/util";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

export default {
    name: 'widget15',
    mixins: [defaultProfileImageMixin, formateDateMixin],
    props: {
        corpid: String,
        codegroupid: String
    },
    data() {
        return {
            total: 0,
            remaining: 0,
            used: 0,
            form: {
                startdate: '',
                enddate: '',
                online: true,
                corpid: this.$route.params.corpId,
                codegroupid: this.$route.params.codegroupId
            },
            fields: [
                { key: "image", label: "" },
                { key: "name", label: "Code/Email" },
                { key: "redeemdate", label: "Redeemed Date" },
                { key: "expiredate", label: "Expiration Date" },
                { key: "isused", label: "Is Used?" },
                { key: "status", label: "Online/Offline" }
            ],
            filter: null,
            filterOn: [],
            excel_fields: {
                'Code': 'code',
                'Used': 'isused',
                'Expire': {
                    field: 'expiration_date',
                    callback: (value) => {
                        let myDate = new Date(value.seconds * 1000);
                        return `${myDate.getMonth() + 1}/${myDate.getDate()}/${myDate.getFullYear()}`;
                    }
                },
                'First Name': {
                    field: 'user.firstname',
                    callback: (value) => {
                        if (value && value != "") {
                            return value
                        } else {
                            return ""
                        }
                    }
                },
                'Email': {
                    field: 'user.email',
                    callback: (value) => {
                        if (value && value != "") {
                            return value 
                        } else {
                            return ""
                        }
                    }
                }
            },
            perPage: 10,
            currentPage: 1,
            isDisabled: false,
            options: [
                { value: true, text: 'Online' },
                { value: false, text: 'Offline' }
            ],
            onlineClick: [],
        }
    },
    methods: {
        changeOnlineStatus(status, data) {
            Swal.fire({
                title: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3b5998",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, change it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.onlineClick.push(data.item.id)
                    this.$store.dispatch("updateCorpUserOnlineStatus", {
                        status: status,
                        corpuserid: data.item.id
                    });
                    Swal.fire("Processing!", "The online status will update shortly.", "success");
                }
            });
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            // this.totalRows = filteredItems.length
            this.currentPage = 1
        }
    },
    mounted() {
        this.$store.dispatch("getCodeGroupListDetails", { corpId: this.$route.params.corpId, codegroupId: this.$route.params.codegroupId });
        this.$store.dispatch("getCodes", { codegroupId: this.$route.params.codegroupId });

        const form = KTUtil.getById("kt_save_code_form");
        this.fv1 = formValidation(form, {
            fields: {
                startdate: {
                    validators: {
                        notEmpty: {
                            message: "Start date is required",
                        },
                    },
                },
                enddate: {
                    validators: {
                        notEmpty: {
                            message: "End date is required",
                        },
                    },
                },
                online: {
                    validators: {
                        notEmpty: {
                            message: "Status is required",
                        },
                    },
                }
            },
            plugins: {
                trigger: new Trigger(),
                submitButton: new SubmitButton(),
                bootstrap: new Bootstrap(),
            },
        });

        this.fv1.on("core.form.valid", () => {
            this.isDisabled = true

            // set spinner to submit button
            const submitButton = this.$refs["kt_save_code_form"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            this.$store.dispatch("updateCodeGroup", this.form)
                .then(
                    function (value) {
                        submitButton.classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );
                        Swal.fire({
                            title: "",
                            text: "The codes information will update shortly",
                            icon: "success",
                            confirmButtonClass: "btn btn-secondary",
                            heightAuto: true,
                        })
                    },
                    function (error) {
                        submitButton.classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );
                        Swal.fire({
                            title: "",
                            text: error,
                            icon: "error",
                            confirmButtonClass: "btn btn-secondary",
                            heightAuto: true,
                        });
                    });
        });

        this.fv1.on("core.form.invalid", () => {
            Swal.fire({
                title: "",
                text: "Please, provide correct data!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: true,
            });
        });

    },
    computed: {
        ...mapGetters(["codeList", "codeGroupDetails"]),
        rows() {
            return this.codeList.length;
        },
    },
    watch: {
        codeGroupDetails(newValue) {
            this.total = parseInt(newValue.number_licenses);
            this.remaining = parseInt(newValue.total_active);
            this.used = parseInt(newValue.total_used);
            let myDate = new Date(newValue.startdate.seconds * 1000);
            let newDate = new Date(myDate.setDate(myDate.getDate()));
            this.form.startdate = newDate;
            let myDate1 = new Date(newValue.enddate.seconds * 1000);
            let newDate1 = new Date(myDate1.setDate(myDate1.getDate()));
            this.form.enddate = newDate1;
            this.form.online = newValue.online;
        }
    }
}
</script>
